export const featureFlags = {
  manageExtenalUsers: true,
  manageInternalUsers: true,
  manageDepartments: true,
  announcements: true,
  cdpi: true,
  registerArtisanalUser: true,
  registerCompany: true,
  registerCooperative: true,
  registerSoleProprietorship: true,
};

export type FeatureFlags = Record<string, boolean>;
